<template >
  <!-- Layout wrapper -->
  <!-- <loading
    v-model:active="isLoading"
    :can-cancel="true"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  /> -->
  <div class="layout-page">
    <TopBarView />
    <div class="content-wrapper">
      <!-- <Search /> -->
      <!-- Content -->
      <div>
          <div>
            <div class="flex-grow-1 container-p-y px-4">
              <div class="d-flex justify-content-between aling-items-center">
                <div>
                  <h4 class="fw-bold py-3 mb-4">
                    <span class="text-muted fw-light">Store </span> <span v-if="listShow">List</span> <span v-if="formShow">Settings</span>
                  </h4>
                </div>
                <div>
                  <button class="btn btn-primary mt-2" @click="toggleComponent" v-if="listShow">Add</button>
                  <button class="btn btn-primary mt-2" @click="toggleComponent" v-if="formShow">Back to List</button>
                  <!-- <router-link :to="{ name: 'Page' }" class="btn btn-primary mt-2" @click="toggleComponent" v-show="formShow"> -->
                    <!-- Back to  List
                  </router-link> -->
                </div>
              </div>
              <!-- Basic Layout & Basic with Icons -->
                <!-- table  -->
                <div class="mb-4" v-if="listShow">
                  <div class="">
                    <div class="text-nowrap">
                      <List />
                    </div>
                </div>
              </div>
              <!-- form..  -->
              <div v-if="formShow">
                <Form />
              </div>  
            </div>
          </div>
        </div>
      <Footer />
      <!-- / Footer -->

      <div class="content-backdrop fade"></div>
    </div>
    <!-- / Layout wrapper -->
  </div>


</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
// import ThemMenu from "../components/Navbar.vue";
// import Search from "../components/Search.vue";
import List from "./Components/List.vue";
import Form from "./Components/Form.vue";
import { category, categoryCreateData, CategoryUpdateData, categoryDeleteData } from "@/api";
import Footer from "../FooterView.vue";
import TopBarView from "../TopBarView.vue";
import router from "@/router";
// import { formToJSON } from "../../../node_modules/axios/index";

export default defineComponent({
  components: { List, Form, Footer, TopBarView },
  name: "store-view",
  data() {
    return {
      listShow: true,
      formShow: false,
    };
  },
  watch: {
    '$route.params.id': {
      // immediate: true,
      handler(newVal) {
        // console.log('_id900_', newVal);
        this.listShow = !this.listShow;
        this.formShow = !this.formShow;
      },
    },
  },
  methods: {
    toggleComponent() {
      this.listShow = !this.listShow; // Toggles the visibility
      this.formShow = !this.formShow; // Toggles the visibility
      if(this.$route.params.id){
        this.listShow = !this.listShow;
        this.formShow = !this.formShow;
        router.push({ name: "store-view" });
      }
    },
  },

  mounted() {
    // Set isLoading to true when the component is mounted
    //this.showLoadingOverlay();
  },

});
</script>