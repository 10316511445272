import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthPage = _resolveComponent("AuthPage")!
  const _component_HomePage = _resolveComponent("HomePage")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.storeData == null || _ctx.userData === null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_AuthPage)
        ]))
      : (!_ctx.$store.state.auth.token && !_ctx.isAuthenticated)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_AuthPage)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_HomePage)
          ]))
  ]))
}