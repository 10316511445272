<template>
  <div>
    <!-- search form start  -->
    <div class="card mb-4">
      <div class="card-body">    
        <div class="row">
          <div class="input-group">
            <!-- <div class="input-group-prepend">
              <select class="form-select" aria-label="Default select example">
                    <option selected>Search By-</option>
                    <option value="1">Category</option>
                    <option value="2">Product Name </option>
              </select>  
            </div> -->
            <input type="text" class="form-control w-25" placeholder="Enter Search Key !">
            <!-- <div class="input-group"> -->
                <!-- <span class="input-group-text" id="basic-addon1">From</span>
                <input type="date" class="form-control" placeholder="Enter Search Key !">
                <span class="input-group-text" id="basic-addon1">To</span>
                <input type="date" class="form-control" placeholder="Enter Search Key !"> -->
            <!-- </div> -->
            <button type="button" class="btn btn-primary me-1 ms-1"><font-awesome-icon :icon="['fas', 'magnifying-glass']" /></button>
            <button type="button" class="btn btn-default border"><font-awesome-icon :icon="['fas', 'broom']" /></button>            
          </div>
        </div>
      </div>
    </div>
    <!-- search form end  -->
    <!-- table start  -->
    <div class="card mb-1 d-none d-md-block">
      <div class="card-body table-responsive">
        <div class="d-flex justify-content-center align-items-center" v-if="loading">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="table-responsive" v-else>
          <table class="table card-table" v-if="fettchDataArray.length > 0">
            <thead>
              <tr>
                <th>Id</th>
                <!-- <th v-if="userData?.type=='SUPERADMIN'">Company</th> -->
                <th>Customer Name</th>
                <th>Customer Phone</th>
                <th>Total Purchase</th>
                <th>Total Payment</th>
                <th>Due Total</th>
                <!-- <th>Profit</th> -->
                <th>Action</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(dataItem, index) in fettchDataArray" :key="dataItem.id">
                <td>{{ index + 1 }}</td>
                <!-- <td v-if="userData?.type=='SUPERADMIN'">{{ dataItem.store?.name }}</td> -->
                <td>{{ dataItem.customer?.name }}</td>
                <td>{{ dataItem.customer?.phone }}</td>
                <td>{{ dataItem.type }}</td>
                <td>{{ dataItem.type }}</td>
                <td>{{ dataItem.type }}</td>
                <!-- <td>{{ dataItem.user?.firstname }} {{ dataItem.user?.lastname }} - {{ dataItem.user?.type }}</td> -->
                <td>
                  <!-- <button type="button" class="me-2 btn btn-sm btn-secondary " @click="editItem(dataItem.id.toString())">
                    <font-awesome-icon :icon="['fas', 'eye']" /> Details 
                  </button> -->
                  <router-link class="me-2  btn btn-sm btn-secondary" :to="{ name: 'due-form', params: { id: dataItem.id.toString() } }">
                    <font-awesome-icon :icon="['fas', 'eye']" /> Details 
                  </router-link>
                  <!-- <button type="button" class="me-2 btn btn-sm btn-danger" data-bs-toggle="modal" data-bs-target="#staticBackdrop" @click="deleteclick(dataItem.id, dataItem.user_id)">
                    Delete
                  </button> -->
                  <!-- <button type="button" class="me-2 btn btn-sm btn-danger" 
                    data-bs-toggle="modal" data-bs-target="#modalCenter"
                    @click="fetchItem(dataItem)"
                  >
                  <font-awesome-icon :icon="['fas', 'trash-can']" />
                  </button> -->
                </td>
              </tr>
            </tbody>
          </table> 
          <div v-else>
            <h5 class="text-center mb-0">Please Sale Some Products</h5>
          </div>  
        </div>
      </div> 
    </div>
    <!-- // table end -->
    <div class="mobile-view d-block d-md-none">
      <div class="card mb-4" v-for="dataItem in fettchDataArray" :key="dataItem.id">
        <div class="card-body">
        <!-- <span>{{index+1}}</span><br> -->
        <strong> Name: {{ dataItem.customer?.name }}</strong><br/>
        <small>Phone: {{ dataItem.customer?.phone }}</small> <br/>
        <small>Total Purchase: {{ dataItem.type }}</small>
        <p class="text-wrap mb-0">Total Payment: {{ dataItem.type }}</p> 
        <p class="text-wrap">Total Due: {{ dataItem.type }}</p>
        <button type="button" class="me-2 btn btn-sm btn-secondary " @click="editItem(dataItem.id.toString())">
          <font-awesome-icon :icon="['fas', 'eye']" /> Details
        </button>
        <!-- <button @click="editItem(post)">Edit</button> -->
        <!-- <button type="button" class="me-2 btn btn-sm btn-danger" data-bs-toggle="modal" data-bs-target="#staticBackdrop" @click="deleteclick(store.id, store.name)">
          <font-awesome-icon :icon="['fas', 'trash-can']" />
        </button> -->
        </div>
      </div>
    </div>
    
    <!-- pagination start  -->
    <div class="card" v-if="fettchDataArray.length > 0">
      <div class="card-body d-flex justify-content-around flex-wrap">
        <nav aria-label="Page navigation">
          <ul class="pagination flex-wrap">
            <!-- Previous button -->
            <!-- <li class="page-item" @click="previousPage">
              <a class="page-link"><i class="tf-icon bx bx-chevron-left"></i></a>
            </li> -->

            <!-- Page buttons -->
              <li v-for="(page, index) in paginationData.links" :key="index" class="page-item myPagination m-1" :class="{ active: page.active }">
                <button class="page-link" @click="goToPage(Number(page.label))" v-html="page.label"></button>
              </li>
            <!-- Next button -->
            <!-- <li class="page-item" @click="nextPage">
              <a class="page-link"><i class="tf-icon bx bx-chevron-right"></i></a>
            </li> -->
          </ul>
        </nav>
        <div class="row">
          <div class="col-12">
            <div class="input-group ">
              <label class="input-group-text" for="inputGroupSelect01">Size</label>
              <select class="form-select" id="inputGroupSelect01" v-model="pageSize" @change="fetchData">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
        </div>
      </div> 
    </div>
    <!-- // pagination end -->
    <!-- Delete Confirm Modal -->
    <div class="modal fade" id="modalCenter" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header ">
            <h5 class="modal-title text-center" id="modalCenterTitle">Delete Confirmation !</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body text-center">  
            <i class="bx bx-bell bx-lg text-danger mb-3 d-inline-block"></i>      
            <p>Are You Sure to Delete ?</p>
            <p><b>{{ deletevalue.name }}</b> Page !</p>
          </div>
          <div class="modal-footer text-center justify-content-center">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
              Close
            </button>
            <button data-bs-dismiss="modal" type="button" class="btn btn-danger" @click = "deleteItem(deletevalue.id)">Confirm</button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal end  -->
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import axios from '../../../../node_modules/axios/index';
import { useStore } from 'vuex'; // Import useStore from Vuex
import { DOAMIN_PATH } from "@/api";
interface mappingData {
  id: 1,
  type: "",
  name: "",
  phone: "",
  address: "",
  quantity: "",
  total_purchase: "",
  total_payment: "",
  total_due: "",
  product_id: "",
  user_id: "",
  store_id: "",
  supplier_id: '',
  store: {
    id: number,
    name: string
  },
  user: {
    id: number,
    type: string,
    firstname: string,
    lastname: string
  },
  customer:{
    id: number,
    address: "",
    store_id:1
    created_at: "",
    date_of_birth: "",
    name: "",
    nid_no: "",
    phone: "",
    thumb: "",
    updated_at: "",
  }
}
export default defineComponent({
  name: "CategoryList", // Rename the component to a multi-word name
  data() {
    const store = useStore();
    const token = store.state.auth.token || localStorage.getItem('token');
    const userData = store.state.auth.userData || localStorage.getItem('userData');
    const storeId = store.state.auth.storeId || localStorage.getItem('storeId');
    const userId = store.state.auth.userId || localStorage.getItem('userId');

    return {      
      store,
      token,
      userData,
      storeId,
      userId,
      fettchDataArray: ref([] as mappingData[]), // Explicitly define the type as Post[] // Explicitly define the type as Post[]
      deletevalue: {
        id: 0,
        name: "",
      },
      isDataTrueList: true,
      id: '',
      loading: false,
      pageSize: 10, // set a default value
      currentPage: 1, // set a default value
      paginationData: {
        links: [
          {
            label: '',
            url: '',
            active: '',
          }
        ],
        prev_page: 0,
        next_page: 0,
        total: 0,
        per_page: 0,
        current_page: 0,
        last_page: 0,

      }, // Object to store pagination data
    };
  },
  methods: {
    async fetchAllData() {
      try { 
        this.loading = true;
        if (!this.token) {
            throw new Error('Token not found');
          }
          const response = await axios.get(DOAMIN_PATH + 'report-due',
          {
            params: {
              page: this.currentPage,
              size: this.pageSize,
            },
            headers: {
              Authorization: `Bearer ${this.token}`,
              StoreId: this.storeId,
              //UserId: this.userId
            }
          }
          );
        if (response.status === 200) {
          this.fettchDataArray = response.data.data.data;
          this.paginationData = response.data.data;
          this.loading = false;
        }
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },

    // Delete areas data
    async fetchItem(item: any) {
      this.deletevalue = item;
      console.log("deletevalue", this.deletevalue);
    },
    async deleteItem(id: number) {
      try {
        const response = await (axios as any).delete(DOAMIN_PATH + '/'+id, {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          });

        if (response.status == 200) {
          console.log('_delete_success', response);
          this.fetchAllData();
        }
      } catch (error) {
        console.error(error);
      }
    },
        sendData(id: string) {
          this.$emit('data-emitted', {
            isDataTrueList: this.isDataTrueList,
            id,
          });
        },

    editItem(id: any) {
      // Navigate to the edit route with the ID parameter
      this.$router.push({ params: { id } });
    },
    //pagination
    goToPage(pageOrLabel: string | number | null) {
    
    if (pageOrLabel === "Next »" || pageOrLabel === "&laquo; Previous") {
      console.log('Skipping Next/Previous');
      return;
    }
    if (typeof pageOrLabel === 'number') {
      console.log('Setting currentPage  :', pageOrLabel);
      this.currentPage = pageOrLabel;
      console.log('curentPage:', this.currentPage);
      this.fetchAllData();
    } else if (pageOrLabel) {
      const match = pageOrLabel.match(/page=(\d+)/);
      if (match) {
        console.log('else id match:', parseInt(match[1]));
        this.currentPage = parseInt(match[1]);
      }
    }
  
    this.fetchAllData();
  },
  
  
      // Add methods to navigate to the previous and next pages
      previousPage() {
        if (this.paginationData.current_page > 1) {
          this.currentPage = this.paginationData.current_page - 1;
          this.fetchAllData();
        }
      },
  
      nextPage() {
        if (this.paginationData.current_page < this.paginationData.last_page) {
          this.currentPage = this.paginationData.current_page + 1;
          this.fetchAllData();
        }
      },
    async fetchData() {
      // Optionally, you can reset the current page to 1 when the page size changes
      // this.currentPage = 1;
      await this.fetchAllData();
    },
  },
  mounted() {
    this.fetchAllData();
  },
});
</script>