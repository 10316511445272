<template>
  <div class="container-xxl">
    <div class="row " style="min-height: 100vh">
      <div class="col-md-9 col-sm-12 mx-auto">
        <div class="row h-100">
          <div class="col-md-6 mx-auto">
            <div class="d-flex align-items-center justify-content-center h-100">
              <div class="w-100">
                <div class="authentication-wrapper authentication-basic container-p-y">
                  <div class="authentication-inner w-100">
                    <!-- Register -->
                    <div class="card">
                      <div class="card-body">
                        <!-- Logo -->
                        <div class="app-brand justify-content-center">
                          <a href="/" class="app-brand-link gap-2">
                            <span class="app-brand-text demo text-body fw-bold text-uppercase mb-3"> 
                              <img src="https://pos-file.phicsart.com/profile_user.png" alt="n/a" class="img-fluid" style="width:80px; height:80px;">
                            </span>
                          </a>
                        </div> 
                        <!-- /Logo -->
                        <h4 class="mb-2 text-center">Registration Form</h4>
                        <div v-if="errorMessages.password" class="alert alert-danger alert-dismissible" role="alert">
                          {{ errorMessages.password }}
                          <button type="button" class="btn-close" @click="clearError('password')" aria-label="Close"></button>
                        </div>
                        <!-- Email already taken error -->
                        <div v-if="errorMessages.email" class="alert alert-warning alert-dismissible" role="alert">
                          {{ errorMessages.email }}
                          <button type="button" class="btn-close" @click="clearError('email')" aria-label="Close"></button>
                        </div> 
                        <form id="formAuthentication" class="mb-3" @submit.prevent="onRegistration">
                          <div class="mb-3">
                            <label for="firstname" class="form-label">First Name</label>
                            <input type="text" class="form-control" id="firstname" v-model="registrationForm.firstname" placeholder="Enter yout First Name" required>
                          </div>
                          <div class="mb-3">
                            <label for="lastname" class="form-label">Last Name</label>
                            <input type="text" class="form-control" id="lastname" v-model="registrationForm.lastname" placeholder="Enter your Last Name">
                          </div>
                          <div class="mb-3">
                            <label for="email" class="form-label">Email<sup class="text-danger">*</sup></label>
                            <input type="email" class="form-control" id="email" v-model="registrationForm.email" placeholder="Enter your email" required>
                          </div>
                          <div class="mb-3">
                            <label for="phone" class="form-label">Phone</label>
                            <input type="text" class="form-control" id="phone" v-model="registrationForm.phone" placeholder="Enter your Phone" required>
                          </div>
                          <div class="mb-3">
                            <label for="phone" class="form-label">National Indentification Number</label>
                            <input type="text" class="form-control" id="phone" v-model="registrationForm.national_id_no " placeholder="Enter your NID No" required>
                          </div>
                          <div class="mb-3">
                            <label for="phone" class="form-label">Address</label>
                            <textarea type="text" class="form-control" id="phone" v-model="registrationForm.address" placeholder="Enter your Address" rows="3" ></textarea>
                          </div>
                          <div class="mb-3 form-password-toggle">
                            <label for="email" class="form-label">Password<sup class="text-danger">*</sup></label>
                            <div class="input-group input-group-merge">
                              <input :type="showPassword.new ? 'text' : 'password'" class="form-control" v-model="registrationForm.password" placeholder="Enter password" aria-describedby="password" required />
                              <span class="input-group-text cursor-pointer" @click="togglePassword('new')">
                                <i :class="showPassword.new ? 'bx bx-show' : 'bx bx-hide'"></i>
                              </span>
                            </div>
                          </div>
                          <div class="mb-3 form-password-toggle">
                            <label for="email" class="form-label">Confirm Password<sup class="text-danger">*</sup></label>
                            <div class="input-group input-group-merge">
                              <input :type="showPassword.confirm ? 'text' : 'password'" id="password" class="form-control" v-model="registrationForm.password_confirmation" placeholder="Confirm password" required />
                              <span class="input-group-text cursor-pointer" @click="togglePassword('confirm')">
                                <i :class="showPassword.confirm ? 'bx bx-show' : 'bx bx-hide'"></i>
                              </span>
                            </div>
                          </div>
                          <div class="mb-3">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" id="remember-me">
                              <label class="form-check-label" for="remember-me"> Remember Me </label>
                            </div>
                          </div>
                          <div class="mb-3">
                            <button type="submit" class="btn btn-primary w-100 justify-content-center" :disabled="submitBtn">
                                <span v-if="!submitBtn">Registration</span> 
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="submitBtn"></span>
                                <span class="visually-show" v-if="submitBtn"> Loading ....</span>
                              </button>
                          </div>
                        </form>
                        <p class="text-center">
                          <span>Already have an account?</span>
                          <button type="button" class="btn btn-sm" style="color: #696cff" @click="switchToLogin()">
                            <span>Login</span>
                          </button>
                        </p>
                      </div>
                    </div>
                    <!-- /Register -->
                  </div>
                </div>
              </div>               
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>  
</template>
<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import axios from '../../../../node_modules/axios/index';

export default defineComponent({
name: 'RegistrationForm',
// components: {Topbar, Footer},
data() {
  const store = useStore();
  // const token = store.state.auth.token || localStorage.getItem('token');
  // const user = store.state.auth.userData || localStorage.getItem('userData');
  const defaultComponentData = store.state.auth.defaultComponentData || localStorage.getItem('defaultComponentData');  
  return {
    store,
    defaultComponentData,
    registrationForm: {
      email: '',
      password: '',
      password_confirmation: '',
      phone: '',
      firstname: '',
      lastname: '',
      profile_img: '',
      address: '',
      national_id_no: '',
      type: 'USER',      
    },
    errorMessages: {} as Record<string, string>,
    showPassword: {
      new: false,
      confirm: false,
    },
    submitBtn: false,
  };
},
methods: {
  async switchToLogin() {
    localStorage.setItem('defaultComponentData', 'LoginForm');
    this.store.commit('auth/SET_DEFAULT_COMPONENT', 'LoginForm');
    // router.push('/');
    window.location.reload();
  },
  async onRegistration()  {
      try {
        this.submitBtn = true;
        // console.log('_registrationForm', this.registrationForm);
        const response = await axios.post('https://pos-api.phicsart.com/api/register', this.registrationForm);
        if (response.status === 201) {
          // set token into local storage and cookies
          localStorage.setItem('token', response.data.token);
          this.store.commit('auth/SET_TOKEN', response.data.token);
          try {
            const token = this.store.state.auth.token || localStorage.getItem('token');
              if (!token) {
                throw new Error('Token not found');
              }
              const responseuser = await axios.get('https://pos-api.phicsart.com/api/user-info', {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
              );
              const userId = responseuser.data.data.id;
              this.store.commit('auth/SET_USER_ID', userId);
              localStorage.setItem('userId', userId);
              this.store.commit('auth/SET_USER_DATA', responseuser.data.data);
              localStorage.setItem('userData', JSON.stringify(responseuser.data.data));
              this.store.commit('auth/SET_DEFAULT_COMPONENT', 'CompanyForm');
              localStorage.setItem('defaultComponentData', 'CompanyForm');
              window.location.reload();
              this.submitBtn = false;
          } catch (error) {
            this.handleError(error);
            this.submitBtn = false;
          }
        }
      } catch (error) {
        this.handleError(error);
        this.submitBtn = false;
      }
    },
    async handleError (error: any) {
      if (error.response) {
          if (error.response.status === 401) {
            const errorData = error.response.data;
            // Check if 'message' exists before updating errorMessages
            this.errorMessages.message = 'Invalid! Username or Password' || errorData.message;
          } else if (error.response.status === 400) {
            const errorData = error.response.data.errors;
            // Update errorMessages based on the received error data
            this.errorMessages.email = errorData.email ? errorData.email[0] : '';
            this.errorMessages.password = errorData.password ? errorData.password[0] : '';
          }
        } else {
          console.error('Unexpected error:', error);
        }
    },

    // Method to clear specific error
    async clearError (field: string | number) {
      if (typeof field === 'string') {
        this.errorMessages[field] = '';
      }
    },

    // Example usage
    clearEmailError() {
      this.clearError('email');
    },
    async togglePassword(field: string | number) {
      // Use type assertion to tell TypeScript that field is a valid key
      const validField = field as keyof typeof this.showPassword;
      // Update the property directly
      this.showPassword[validField] = !this.showPassword[validField];
    },
},

});
</script>