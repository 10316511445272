<template>
    <!-- Layout container -->
    <div class="layout-page">
    <!-- Navbar -->
    <Topbar />
    <!-- / Navbar -->
    <!-- Content wrapper -->
    <div class="content-wrapper">
      <!-- Content -->
      <div class="px-4 flex-grow-1 container-p-y">
        <div class="row">
          <div class="col-md-8">
            <div class="card mb-4">
              <div class="d-flex align-items-end row">
                <div class="col-sm-7">
                  <div class="card-body">
                    <h5 class="card-title ">
                      <span class="text-primary"> {{userData.firstname}} {{userData.lastname}} </span>                       
                    </h5>
                    <p class="mb-0">{{userData.email}}</p>
                    <p class="mb-0"> {{userData.phone}}</p>
                    <p class="mb-0">{{ userData.address }}</p>

                    <a
                      href="javascript:;"
                      class="btn btn-sm btn-outline-primary mt-2"
                      >Free Plan</a>
                  </div>
                </div>
                <div class="col-sm-5 text-center text-sm-left">
                  <div class="card-body pb-0 px-0 px-md-4">
                    <img
                      src="/assets/img/illustrations/man-with-laptop-light.png"
                      height="140"
                      alt="View Badge User"
                      data-app-dark-img="illustrations/man-with-laptop-dark.png"
                      data-app-light-img="illustrations/man-with-laptop-light.png"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="card mb-4">
              <div class="row row-bordered g-0">
                <div class="col-md-12">
                  <h5 class="card-header m-0 me-2 pb-3">Graphical Reports</h5> 
                  <div id="incomeChart" class="px-2"> 
                    <apexchart type="area" height="350" :options="chartOptions" :series="series"></apexchart>
                  </div>    
                </div>
                <!-- <div class="col-md-3">
                  <div class="card-body">
                    <div class="text-center">
                      <div class="dropdown">
                        <button
                          class="btn btn-sm btn-outline-primary dropdown-toggle"
                          type="button"
                          id="growthReportId"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          2022
                        </button>
                        <div
                          class="dropdown-menu dropdown-menu-end"
                          aria-labelledby="growthReportId"
                        >
                          <a class="dropdown-item" href="javascript:void(0);"
                            >2021</a
                          >
                          <a class="dropdown-item" href="javascript:void(0);"
                            >2020</a
                          >
                          <a class="dropdown-item" href="javascript:void(0);"
                            >2019</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="growthChart"></div>
                  <div class="text-center fw-semibold pt-3 mb-2">
                    62% Company Growth
                  </div>

                  <div
                    class="d-flex px-xxl-4 px-lg-2 p-4 gap-xxl-3 gap-lg-1 gap-3 justify-content-between"
                  >
                    <div class="d-flex">
                      <div class="me-2">
                        <span class="badge bg-label-primary p-2"
                          ><i class="bx bx-dollar text-primary"></i
                        ></span>
                      </div>
                      <div class="d-flex flex-column">
                        <small>2022</small>
                        <h6 class="mb-0">$32.5k</h6>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="me-2">
                        <span class="badge bg-label-info p-2"
                          ><i class="bx bx-wallet text-info"></i
                        ></span>
                      </div>
                      <div class="d-flex flex-column">
                        <small>2021</small>
                        <h6 class="mb-0">$41.2k</h6>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="card mb-4">
              <div class="row row-bordered g-0">
                <div class="col-md-12">
                  <h5 class="card-header m-0 me-2 pb-3">Graphical Reports</h5>
                  <div id="incomeChart">
                    <apexchart type="line" height="350" :options="chartOptions2" :series="series2"></apexchart>
                  </div>    
                </div>
                <!-- <div class="col-md-3">
                  <div class="card-body">
                    <div class="text-center">
                      <div class="dropdown">
                        <button
                          class="btn btn-sm btn-outline-primary dropdown-toggle"
                          type="button"
                          id="growthReportId"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          2022
                        </button>
                        <div
                          class="dropdown-menu dropdown-menu-end"
                          aria-labelledby="growthReportId"
                        >
                          <a class="dropdown-item" href="javascript:void(0);"
                            >2021</a
                          >
                          <a class="dropdown-item" href="javascript:void(0);"
                            >2020</a
                          >
                          <a class="dropdown-item" href="javascript:void(0);"
                            >2019</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="growthChart"></div>
                  <div class="text-center fw-semibold pt-3 mb-2">
                    62% Company Growth
                  </div>

                  <div
                    class="d-flex px-xxl-4 px-lg-2 p-4 gap-xxl-3 gap-lg-1 gap-3 justify-content-between"
                  >
                    <div class="d-flex">
                      <div class="me-2">
                        <span class="badge bg-label-primary p-2"
                          ><i class="bx bx-dollar text-primary"></i
                        ></span>
                      </div>
                      <div class="d-flex flex-column">
                        <small>2022</small>
                        <h6 class="mb-0">$32.5k</h6>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="me-2">
                        <span class="badge bg-label-info p-2"
                          ><i class="bx bx-wallet text-info"></i
                        ></span>
                      </div>
                      <div class="d-flex flex-column">
                        <small>2021</small>
                        <h6 class="mb-0">$41.2k</h6>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <!-- number status -->
          <div class="col-md-4">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-6 mb-4">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-center align-items-center" v-if="loading">
                      <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <div v-else>
                      <div class="card-title d-flex align-items-start justify-content-between">
                        <div class="avatar flex-shrink-0">
                          <img
                            src="/assets/img/icons/unicons/chart-success.png"
                            alt="chart success"
                            class="rounded"
                          />
                        </div>
                      </div>
                      <span class="fw-semibold d-block mb-1">Number of Product</span>
                      <h3 class="card-title mb-2">{{ fettchDataArray.total_product }}</h3>
                      <!-- <small class="text-success fw-semibold"
                        ><i class="bx bx-up-arrow-alt"></i> +72.80%</small
                      > -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 mb-4">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-center align-items-center" v-if="loading">
                      <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <div v-else>
                      <div class="card-title d-flex align-items-start justify-content-between">
                        <div class="avatar flex-shrink-0">
                          <img
                            src="/assets/img/icons/unicons/cc-warning.png"
                            alt="Credit Card"
                            class="rounded"
                          />
                        </div>
                      </div>
                      <span class="fw-semibold d-block mb-1">Alert Product</span>
                      <h3 class="card-title mb-2">{{ fettchDataArray.alertProductList }}</h3>
                      <!-- <small class="text-success fw-semibold"
                        ><i class="bx bx-up-arrow-alt"></i> +28.14%</small
                      > -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
             <div class="row ">
              <div class="col-6 mb-4 mb-4">
                <div class="card ">
                  <div class="card-body">
                    <div class="d-flex justify-content-center align-items-center" v-if="loading">
                      <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <div v-else>
                      <div class="card-title d-flex align-items-start justify-content-between" >
                      <div class="avatar flex-shrink-0">
                        <img src="/assets/img/icons/unicons/cube-secondary.png" alt="cube" class="rounded">
                      </div>
                    </div>
                    <span class="fw-medium d-block mb-1">Orders</span>
                    <h3 class="card-title mb-2"> {{ fettchDataArray.totalOrderCount }}</h3>
                    <!-- <small class="text-danger fw-medium"><i class="bx bx-down-arrow-alt"></i> -13.24%</small> -->
                    </div>
                    
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-6 mb-4">
                <div class="card ">
                  <div class="card-body">
                    <div class="d-flex justify-content-center align-items-center" v-if="loading">
                      <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <div v-else>
                      <div
                        class="card-title d-flex align-items-start justify-content-between" 
                      >
                        <div class="avatar flex-shrink-0">
                          <img
                            src="/assets/img/icons/unicons/wallet-info.png"
                            alt="Credit Card"
                            class="rounded"
                          />
                        </div>
                      </div>
                      <span>Sales</span>
                      <h3 class="card-title text-nowrap mb-1">{{ fettchDataArray.totalSaleCountSum }}</h3>
                      <!-- <small class="text-success fw-semibold"><i class="bx bx-up-arrow-alt"></i> +28.42%</small> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6 mb-4">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-center align-items-center" v-if="loading">
                      <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <div v-else> 
                      <div class="card-title d-flex align-items-start justify-content-between">
                        <div class="avatar flex-shrink-0">
                          <img
                            src="/assets/img/icons/unicons/paypal.png"
                            alt="Credit Card"
                            class="rounded"
                          />
                        </div>
                      </div>
                      <span class="d-block mb-1">Total Due</span>
                      <h3 class="card-title text-nowrap mb-2"> {{ fettchDataArray.totalDueCountSum }}</h3>
                      <!-- <small class="text-danger fw-semibold"
                        ><i class="bx bx-down-arrow-alt"></i> -14.82%</small
                      > -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 mb-4">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-center align-items-center" v-if="loading">
                      <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <div v-else>
                      <div class="card-title d-flex align-items-start justify-content-between" >
                        <div class="avatar flex-shrink-0">
                          <img
                            src="/assets/img/icons/unicons/cc-primary.png"
                            alt="Credit Card"
                            class="rounded"
                          />
                        </div>
                      </div>
                      <span class="fw-semibold d-block mb-1">Total Payment</span>
                      <h3 class="card-title mb-2"> {{ fettchDataArray.totalPaymentCountSum }}</h3>
                      <!-- <small class="text-success fw-semibold"
                        ><i class="bx bx-up-arrow-alt"></i> +28.14%</small
                      > -->
                    </div>                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end of number status -->
        </div>
      </div>
      <!-- / Content -->
      <!-- Footer -->
      <Footer />
      <!-- / Footer -->
      <!-- <div class="layout-overlay layout-menu-toggle" @click="toggleClass" ></div> -->
    </div>
    <!-- Content wrapper -->
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import { defineComponent, ref, defineAsyncComponent } from 'vue';
import Topbar from '../TopBarView.vue';
import Footer from '../FooterView.vue';
import { useStore } from 'vuex';
import axios from '../../../node_modules/axios/index';
import { DOAMIN_PATH } from "@/api";
// apex chart
// import ChartView from './ChartView.vue';

import VueApexCharts from 'vue3-apexcharts';
export default defineComponent({
  name: 'DashboardVue',
  components: {Topbar, Footer, apexchart: VueApexCharts},
  data() {
    const store = useStore();
    const token = store.state.auth.token || localStorage.getItem('token');
    const userData = store.state.auth.userData || localStorage.getItem('userData');
    const storeId = store.state.auth.storeId || localStorage.getItem('storeId');
    const userId = store.state.auth.userId || localStorage.getItem('userId');
    return {
      token,
      userData: JSON.parse(userData),
      storeId,
      userId,
      isActiveMenu: false,
      loading: false,
      series: [{
        name: 'Buy',
        data: [31, 40, 28, 51, 42, 109, 100]
      }, 
      {
        name: 'Sale',
        data: [11, 32, 45, 32, 34, 52, 41]
      },
      {
        name: 'Profit',
        data: [13, 35, 50, 60, 30, 37, 45]
      },
    ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'area'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'datetime',
          categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
        },
        toolbar: {
          show: true, // Hide the toolbar, including the menu icon
        },
      },
      fettchDataArray: {
        package_info: null,
        total_category: null,
        total_product: null,
        alertProductList: null,
        totalOrderCount: null,
        totalSaleCountSum: null,
        totalPaymentCountSum: null,
        totalDueCountSum: null
      },
      series2: [{
            name: 'Buy',
            type: 'column',
            data: [2, 5, 4, 2.5, 7, 1.5, 8.3, 5.8]
          }, {
            name: 'Sale',
            type: 'column',
            data: [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5]
          }, {
            name: 'Profit',
            type: 'line',
            data: [20, 29, 37, 36, 44, 45, 50, 58]
          }],
      chartOptions2: {
        chart: {
          height: 350,
          type: 'line',
          stacked: false
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [1, 1, 4]
        },
        title: {
          text: '',
          align: 'left',
          offsetX: 110
        },
        xaxis: {
          categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#008FFB'
            },
            labels: {
              style: {
                colors: '#008FFB',
              }
            },
            title: {
              text: "Income (thousand crores)",
              style: {
                color: '#008FFB',
              }
            },
            tooltip: {
              enabled: true
            }
          },
          {
            seriesName: 'Income',
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#00E396'
            },
            labels: {
              style: {
                colors: '#00E396',
              }
            },
            title: {
              text: "Operating Cashflow (thousand crores)",
              style: {
                color: '#00E396',
              }
            },
          },
          {
            seriesName: 'Revenue',
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#FEB019'
            },
            labels: {
              style: {
                colors: '#FEB019',
              },
            },
            title: {
              text: "Revenue (thousand crores)",
              style: {
                color: '#FEB019',
              }
            }
          },
        ],
        tooltip: {
          fixed: {
            enabled: true,
            position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
            offsetY: 30,
            offsetX: 60
          },
        },
        legend: {
          horizontalAlign: 'left',
          offsetX: 40
        }
      },
    };
  },
  methods: {
    // Method to toggle between login and registration forms
    async fatchDashboardData() {
      try { 
        this.loading = true;
        if (!this.token) {
            throw new Error('Token not found');
          }
          const response = await axios.get(DOAMIN_PATH + 'report-dashboard',
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
              StoreId: this.storeId,
              UserType: this.userData.type,
            }
          }
          );
        if (response.status === 200) {
          this.fettchDataArray = response.data.data;
          this.loading = false;
        }
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    toggleClass() {
      this.isActiveMenu = !this.isActiveMenu;
      const html = document.querySelector("html");
      if (html) {
        html.classList.toggle("layout-menu-expanded", this.isActiveMenu);
      }
    },
  },
  mounted() {
    this.fatchDashboardData();
    console.log('__userdata__', this.userData.type);
    // apex chart
  }
});
</script>
<style scoped>
.dashboard {
  text-align: center;
}
.apexcharts-canvas + .apexcharts-toolbar {
  display: none !important;
}
</style>

