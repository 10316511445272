<template>
    <div class="container-xxl">
      <div class="authentication-wrapper authentication-basic container-p-y">
        <div class="authentication-inner" style="max-width: 550px; margin: 0 auto;">
          <!-- login -->
          <div class="card">
            <div class="card-body">
               <!-- Logo -->
              <div class="app-brand justify-content-center">
                <a href="/" class="app-brand-link gap-2">
                  <span class="app-brand-text demo text-body fw-bold text-uppercase mb-3"> 
                    <img src="https://pos-file.phicsart.com/profile_user.png" alt="n/a" class="img-fluid" style="width:80px; height:80px;">
                  </span>
                </a>
              </div>
              <!-- /Logo -->
              <h4 class="mb-2 text-center">Add Business Info</h4>
              <div v-if="loading">
                <div class="d-flex justify-content-center align-items-center" >
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
              <div v-else>
                <form id="formAuthentication" class="mb-3" @submit.prevent="CompanyRegistration">
                  <div class="mb-3">
                      <label for="companyEmail" class="form-label">Store Type</label>
                        <select class="form-select" v-model="storeForm.type_id" :required="storeForm.type_id == null">
                          <option select value=null disabled>-- Select Category-- </option>
                          <option v-for="typeForms in typeForm" :key="typeForms.id" :value="typeForms.id">
                            {{ typeForms.title }}
                          </option>
                        </select>
                    </div>
                    <div class="mb-3">
                      <label for="companyName" class="form-label">Store Name</label>
                      <input type="text" class="form-control" id="companyName" v-model="storeForm.name" placeholder="Store Name">
                    </div>
                    <div class="mb-3">
                      <label for="companyEmail" class="form-label">Store Email</label>
                      <input type="email" class="form-control" id="companyEmail" v-model="storeForm.email" placeholder="Store Email" required>
                    </div>                  
                    <div class="mb-3">
                      <label for="companyphone" class="form-label">Store Phone</label>
                      <input type="text" class="form-control" id="companyphone" v-model="storeForm.phone" placeholder="Store Phone" required>
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Store Address</label>
                      <textarea class="form-control" placeholder="Store Address" rows="3" v-model="storeForm.address"></textarea>
                    </div>
                  <div class="mb-3">
                    <button type="submit" class="btn btn-primary w-100 justify-content-center" :disabled="submitBtn">
                      <span v-if="!submitBtn">Save</span> 
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="submitBtn"></span>
                      <span class="visually-show" v-if="submitBtn"> Saving ....</span>
                    </button>
                  </div>
                </form>                
              </div>
            </div>
          </div>
          <!-- /Register -->
        </div>
      </div>
  </div>    
</template>
<script lang="ts">
import { Ref, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import axios from '../../../../node_modules/axios/index';
import { DOAMIN_PATH } from "@/api";
export default defineComponent({
  name: 'CompanyForm',
  // components: {Topbar, Footer},
  data() {
    const storeInstance = useStore();
    const userlocalStorage = localStorage.getItem('userData');
      const parsedUser = userlocalStorage ? JSON.parse(userlocalStorage) : null || storeInstance.state.auth.userData;
      // userData.value = parsedUser;
    return {
      storeInstance,
      loading: false,
      storeForm: {
        name: '',
        email: parsedUser.email,
        phone: '',
        address: '',
        user_id: '',
        type_id: null,
        is_default: 1
      },
      typeForm: [{
        id: 0,
        title: '',
        description: '',
      }],
      submitBtn: false,

    };
  },
  methods: {
    async CompanyRegistration () {
      try {
        this.submitBtn = true;
        const token = this.storeInstance.state.auth.token || localStorage.getItem('token');
        if (!token) {
          throw new Error('Token not found');
        }
        console.log(this.storeForm);
        if(this.storeForm.type_id == null){
          const formSelect = document.querySelector('.form-select');
          if (formSelect) {
              formSelect.classList.add('is-invalid');
              this.submitBtn = false;
          }
        }
        else{
            //set the user_id into storeForm object
          this.storeForm.user_id = this.storeInstance.state.auth.userId || localStorage.getItem('userId');
          const response = await axios.post(DOAMIN_PATH + 'store',
              this.storeForm,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                }
              }
            );
           if(response.status === 201){
            const StoreId = response.data.data.id;          
            this.storeInstance.commit('auth/SET_STORE_ID', StoreId);
            localStorage.setItem('storeId', StoreId);
            localStorage.setItem('defaultStoreData', JSON.stringify(response.data.data));
            this.storeInstance.commit('auth/SET_DEFAULT_STORE_DATA', response.data.data);
            window.location.reload();   
            this.submitBtn = false;
            console.log("store data",response.data.data);
            console.log("store_id", localStorage.getItem('storeId'));
            console.log("store_data", localStorage.getItem('defaultStoreData'));
           }   
        }
                       
      } catch (error) {
        console.error('Error registering company:', error);
        this.submitBtn = false;
      }
    },
    async FatchType () {
      try {
        this.loading = true;
        const token = this.storeInstance.state.auth.token || localStorage.getItem('token');
        if (!token) {
          throw new Error('Token not found');
        }
        //set the user_id into storeForm object
        const response = await axios.get(DOAMIN_PATH + 'type',
            {
              headers: {
                Authorization: `Bearer ${token}`,
              }
            }
          );
         if(response.status === 200){
          this.typeForm = response.data.data.data;  
          this.submitBtn = false;
          this.loading = false;
         }                  
      } catch (error) {
        console.error('Error registering company:', error);        
      }
    }
  },
  mounted() {
  // Set isLoading to true when the component is mounted
    this.FatchType();
  },
});
</script>

