<template>
     <div class="" v-if="isDataTrue == false">
      <div v-if="successReport" class="alert alert-success alert-dismissible" role="alert">
        Successfully
        <button type="button" class="btn-close"  aria-label="Close"></button>
      </div>
      <form name="" @submit.prevent="handleSubmit">
        <div class="row">
          <!-- Basic Layout -->
          <div class="col-md-12 col-sm-12">
            <div class="card">
              <div class="card-body">
                <div class="card-body">
                  <div class="row mb-3">
                    <label
                      class="col-sm-12 col-form-label"
                      for="basic-icon-default-fullname"
                      >Store Name</label
                    >
                    <div class="col-sm-12">
                      <div class="input-group input-group-merge">
                        <span
                          id="basic-icon-default-fullname2"
                          class="input-group-text"
                          ><i class="bx bx-buildings"></i
                        ></span>
                        <input
                          type="text"
                          class="form-control"
                          id="basic-icon-default-fullname"
                          placeholder="Store Name"
                          v-model="formData.name"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label
                      class="col-sm-12 col-form-label"
                      for="basic-icon-default-fullname"
                      >Store Type</label
                    >
                    <div class="col-sm-12">
                      <div class="input-group input-group-merge">
                        <span
                          id="basic-icon-default-fullname2"
                          class="input-group-text"
                          ><i class="bx bx-buildings"></i
                        ></span>
                        <!-- <input
                          type="text"
                          class="form-control"
                          id="basic-icon-default-fullname"
                          placeholder="Store Name"
                          v-model="formData.type_id"
                          required
                        /> -->
                        <select class="form-select" v-model="formData.type_id" :disabled="isRouteParamIdPresent">
                          <option value="">-- Select Type-- </option>
                          <option v-for="type in fetchTypeList" :key="type.id" :value="type.id">{{ type.title }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <!-- <CkEditor></CkEditor> -->
                  <div class="row mb-3">
                    <label
                      class="col-sm-12 col-form-label"
                      for="basic-icon-default-company"
                      >Store Email</label
                    >
                    <div class="col-sm-12">
                      <div class="input-group input-group-merge">
                        <span
                          id="basic-icon-default-company2"
                          class="input-group-text"
                          ><i class="bx bx-envelope"></i
                        ></span>
                        <input
                          type="email"
                          id="basic-icon-default-company"
                          class="form-control"
                          placeholder="Store Email"
                          v-model="formData.email"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label
                      class="col-sm-12 form-label"
                      for="basic-icon-default-phone"
                      >Store Phone</label
                    >
                    <div class="col-sm-12">
                      <div class="input-group input-group-merge">
                        <span
                          id="basic-icon-default-company2"
                          class="input-group-text"
                          ><i class="bx bx-buildings"></i
                        ></span>
                        <input
                          type="text"
                          id="basic-icon-default-phone"
                          class="form-control phone-mask"
                          placeholder="Phone"
                          v-model="formData.phone"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label">Store Address</label>
                    <div >
                      <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="formData.address" placeholder="Store Address"></textarea>
                    </div>
                  </div>
                    <!-- <div class="form-check mb-3">
                      <input class="form-check-input" type="checkbox" value="" id="defaultCheck3" v-model="formData.is_default" true-value="1" false-value="0">
                      <label class="form-check-label" for="defaultCheck3">
                        Is Default
                      </label>
                    </div> -->
                  <div class="col-sm-12">
                    <button type="submit" class="btn btn-primary my-2 py-2" :disabled="submitBtn">
                      <span v-if="!submitBtn">Save</span> 
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="submitBtn"></span>
                      <span class="visually-show" v-if="submitBtn"> Saving...</span>
                    </button>
                </div>
                </div>
                  
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
// import CkEditor from "../../../components/Ckeditor.vue";
import axios from '../../../../node_modules/axios/index';
import { useStore } from 'vuex'; // Import useStore from Vuex
import { DOAMIN_PATH } from "@/api";
interface Post {
  id: number;
  company_id: string;
  email: string;
  name: string;
  phone: string;
  user: object;
  user_id: string; // Assuming this property exists
}
export default defineComponent({
  // components: { CkEditor },
  name: "store-form", // Rename the component to a multi-word name
    data() {
      const store = useStore();
      const token = store.state.auth.token || localStorage.getItem('token');
      const userId = store.state.auth.userId || localStorage.getItem('userId');
      const storeId = store.state.auth.storeId || localStorage.getItem('storeId');
        return {
            siteInfoData: ref([] as Post[]),
            successReport: false,
            storeId: '',
            itemId: '',
            companyId: '',
            store,
            token,
            userId, 
            formData: {
              id: Number,
              company_id: "",
              name: "",
              email: "",
              phone: "",
              address: "",
              logo: "",
              cover_img: "",
              user_id: "",
              is_default: 0,
              type_id: '',
            },
            submitBtn: false,
            fetchTypeList: [{
              id: Number,
              title: '',
            }],  
        }
    },
  props: {
    isDataTrue: Boolean
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.fetchData(newVal);
          this.itemId = newVal;
        }
      },
    },
  },
  computed: {
    isRouteParamIdPresent() {
      return this.$route.params.id !== undefined;
    }
  },
  methods: {
    async fetchData(id: number) {
      try {   
        if (!this.token) {
          throw new Error('Token not found');
        }
        const response = await (axios as any).get(DOAMIN_PATH + `store/${id}`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });
        console.log('_page_data_by_ID_', response);
        if (response.status === 200) {
          const data = response.data;
          this.formData = data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async fetchTypeFunc() {
      try {
        if (!this.token) {
          throw new Error('Token not found');
        }
        const response = await (axios as any).get(DOAMIN_PATH +`type`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
            storeId: this.storeId,
          },
        });
        console.log('_type_list', response);
        if (response.status === 200) {
          this.fetchTypeList = response.data.data.data;
          //  this.fetchTypeList = response.data.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },  
    async handleSubmit() {
      try {
        this.submitBtn = true;
        if (this.itemId) {
          const response = await (axios as any).put(
            DOAMIN_PATH + 'store/' + this.itemId,
            this.formData,
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
              }
            }
          );
          if
          (response.status == 204) {
            console.log();
            this.successReport = true;
            this.submitBtn = false;
            setTimeout(() => { this.successReport = false; }, 3000);
          }
        }  
        else {
          this.submitBtn = true;
          this.formData.is_default = 0;
          this.formData.user_id = this.userId;
          const response = await (axios as any).post(DOAMIN_PATH + 'store', this.formData,
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
              }
            }
          );
          if (response.status == 201) {
            // console.log('_save_success', response);
            this.formData = {
              id: Number,
              company_id: "",
              name: "",
              email: "",
              phone: "",
              address: "",
              logo: "",
              cover_img: "",
              user_id: this.userId,
              is_default: 0,
              type_id: '',
            };
            this.successReport = true;
            this.submitBtn = false;
            setTimeout(() => { this.successReport = false; }, 3000);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

  },
mounted() {
    // Set isLoading to true when the component is mounted
    this.fetchTypeFunc();
  },
})
</script>