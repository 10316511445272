<template>
    <!-- Layout container -->
    <!-- <div class="navbar fixed-top"> -->
    <div>
        <!-- Navbar -->
        <nav
      class="layout-navbar navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme "
      id="layout-navbar"
    >
      <div
        class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none"
      >
        <!-- <a class="nav-item nav-link px-0 me-xl-4 theme-responsive-menu" onclick="toggleSidebar()" @click="toggleClass" > -->
        <a class="nav-item nav-link px-0 me-xl-4 theme-responsive-menu" onclick="toggleSidebar()" >
          <i class="bx bx-menu bx-sm"></i>
        </a>
      </div>

      <div
        class="navbar-nav-right d-flex align-items-center"
        id="navbar-collapse"
      >
        <!-- Search -->
        <div class="navbar-nav align-items-center">
          <div class="nav-item d-flex align-items-center">
            <h5 class="m-0 p-0 text-primary">
              <span>
                <router-link to="/"> {{ (userDefaultData as unknown as Array<{ id: number; name: string }>[0]).name }}</router-link>               
              </span>
            </h5>
          </div>
        </div>
        <!-- /Search -->
        <ul class="navbar-nav flex-row align-items-center ms-auto">
          <!-- Place this tag where you want the button to render. -->
          <li class="nav-item lh-1 me-3">
            <router-link to="/setting"
              class="github-button d-none d-md-inline-block"
              >{{ (userData as unknown as Array<{ id: number; firstname: string }>[0]).firstname }} {{ (userData as unknown as Array<{ id: number; lastname: string }>[0]).lastname }}</router-link>
          </li>
          <!-- User -->
          <li class="nav-item navbar-dropdown dropdown-user dropdown">
            <a
              class="nav-link dropdown-toggle hide-arrow"
              href="javascript:void(0);"
              data-bs-toggle="dropdown"
            >
              <div class="avatar avatar-online">
              <img
                src="/pos_images/POS.png"
                alt="n/a"
                class="w-px-40 h-auto rounded-circle"
              />
              </div>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a class="dropdown-item" href="#">
                  <div class="d-flex">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar avatar-online">
                        <img
                          src="/pos_images/POS.png"
                          alt="n/a"
                          class="w-px-40 h-auto rounded-circle"
                        />
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <span class="fw-semibold d-block"> {{ (userData as unknown as Array<{ id: number; firstname: string }>[0]).firstname }} {{ (userData as unknown as Array<{ id: number; lastname: string }>[0]).lastname }}</span>
                      <small class="text-muted">{{ (userData as unknown as Array<{ id: number; email: string }>[0]).email }}</small><br>
                      <small class="text-muted">{{ (userData as unknown as Array<{ id: number; name: string }>[0]).name }}</small>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <div class="dropdown-divider"></div>
              </li>
              <li>
                <router-link to="/setting" class="dropdown-item" href="#">
                  <i class="bx bx-user me-2"></i>
                  <span class="align-middle">My Profile</span>
                </router-link>
              </li>
              <li>
                <router-link to="/securitysettings" class="dropdown-item" href="#">
                  <i class="bx bx-cog me-2"></i>
                  <span class="align-middle">Security Settings</span>
                </router-link>
              </li>
              <li>
                <router-link to="/store" class="dropdown-item">
                  <i class="bx bx-cog me-2"></i>
                  <span class="align-middle" v-if="userData.type == 'USER'">My Store</span>
                  <span class="align-middle" v-else>Manage Store</span>
                </router-link>
              </li>
              <li>
                <div class="dropdown-divider"></div>
              </li>
              <li>
                <button class="dropdown-item" type="submit" @click="logout">
                  <i class="bx bx-power-off me-2"></i>
                  <span class="align-middle">Log Out</span>
                </button>
              </li>
            </ul>
          </li>
          <!--/ User -->
        </ul>
      </div>
        </nav>
        <!-- / Navbar -->
    </div>
    <!-- Overlay -->
    <!-- <div class="layout-overlay layout-menu-toggle" @click="toggleClass"></div> -->
</template>
<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import axios from '../../node_modules/axios/index';
// import axios from 'axios';
import { useStore } from 'vuex'; // Import useStore from Vuex
import router from '@/router';
import { DOAMIN_PATH } from "@/api";
interface Company {
  id: number;
  store_id: string;
  name: string;
  // ... other properties
} 
const companies = ref([]);

let userData = ref([]);
let storeId = ref([]);
let userId = ref([]);
let typeId = ref([]);
let userDefaultData = ref<Company[]>([]);

// const defaultCompany = ref<Company[]>([]);

const localStorageCompany = ref([]);

export default defineComponent({
  name: 'TopBar',
  components: {},
  data() {
    const storeInstance = useStore();
    return {};
  },
  methods: {},
  mounted() {
    // Set isLoading to true when the component is mounted
  },
  async created() {
    // show the loading overlay
  },

  setup() {
    let storeInstance = useStore();
    const isToggled = ref(false);
    const toggleClass = () => {
      isToggled.value = !isToggled.value;
      const html = document.querySelector('html');
      if (html) {
        html.classList.toggle('layout-menu-expanded', isToggled.value);
      }
    };

    // Function to show the modal
    const getUserInfo = async () => {
      userData.value = storeInstance.state.auth.userData || localStorage.getItem('userData');
      const userlocalStorage = localStorage.getItem('userData');
      const parsedUser = userlocalStorage ? JSON.parse(userlocalStorage) : null || storeInstance.state.auth.userData;
      userData.value = parsedUser;
      storeId = storeInstance.state.auth.storeId || localStorage.getItem('storeId');
      userId = storeInstance.state.auth.userId || localStorage.getItem('userId');
      typeId = storeInstance.state.auth.typeId || localStorage.getItem('typeId');
      const localStorageData = localStorage.getItem('defaultStoreData'); 
      const parsedData = localStorageData ? JSON.parse(localStorageData) : null || storeInstance.state.auth.defaultStoreData;
      
      //userDefaultData.value = storeInstance.state.auth.defaultCompanyData || parsedData || [];
      if (Array.isArray(parsedData) && parsedData.length === 1) {
      // If parsedData is an array with a single item, convert it to an object
        userDefaultData.value = parsedData[0];
      } else if (Array.isArray(parsedData)) {
        // If parsedData is an array with multiple items, assign it directly to userDefaultData
        userDefaultData.value = parsedData;
      } else if (parsedData && typeof parsedData === 'object') {
        // If parsedData is already an object, assign it directly to userDefaultData
        userDefaultData.value = parsedData;
      } else {
        // Handle other cases or assign an empty object
        userDefaultData.value = [parsedData];
      }
    };
   const storeInfo = async () => {
    const token = storeInstance.state.auth.token || localStorage.getItem('token');  
    const userId = storeInstance.state.auth.userId || localStorage.getItem('userId');  
    if (!token) {
      throw new Error('Token not found');
    }
    const response = await axios.get(DOAMIN_PATH + 'store',
    {
      headers: {
        Authorization: `Bearer ${token}`,
        UserId: userId
      }
    }
    );
    if (response.status === 200) {
      const data = response.data;
      // console.log("store data",data);
    }
   }
    const logout = async () => {
      try {
        const token = storeInstance.state.auth.token || localStorage.getItem('token');
            if (!token) {
              throw new Error('Token not found');
            }
        // Call the logout API    
          const response = await axios.post(DOAMIN_PATH + 'logout',
          {},  
          {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }            
          );

        if (response.status === 200) {
          // router.push('/');
          window.location.reload();
          localStorage.removeItem('token');
          localStorage.removeItem('userId');
          localStorage.removeItem('userData');
          localStorage.removeItem('storeData');
          localStorage.removeItem('storeId');
          localStorage.removeItem('typeId');
          localStorage.removeItem('defaultStoreData');
          localStorage.removeItem('defaultComponentData');
        } else {
          // Logout API returned an unexpected status code
          console.error('Logout failed:', response.statusText);
        }
      } catch (error) {
        console.error('Error during logout:', error);
      }
    };

    onMounted(async () => {
      await getUserInfo(); // Retrieve site info
      // console.log('_userDefaultData', userDefaultData);
      storeInfo();
    });

    return {
      isToggled,
      getUserInfo,
      toggleClass,
      logout,
      companies,
      userData,
      storeId,
      userId,
      // defaultCompany,
      userDefaultData
    };
  },
});
</script>

<style scoped>
.dashboard {
  text-align: center;
}
</style>

