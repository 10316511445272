<template>
  <div class="">
   <!-- Basic Layout & Basic with Icons -->
   <div v-if="successReport" class="alert alert-success alert-dismissible" role="alert">
     Successfully
     <button type="button" class="btn-close"  aria-label="Close"></button>
   </div>
   <div class="card d-none d-md-block">
     <div class="card-body">
      <table class="table card-table" >
          <thead>
            <tr>
              <th>Id</th>
              <!-- <th v-if="userData?.type=='SUPERADMIN'">Company</th> -->
              <th>Product Name</th>
              <th>Type</th>
              <th>Quantity</th>
              <th>Date</th>
              <!-- <th>Action</th> -->
            </tr>
          </thead>
          <tbody class="table-border-bottom-0">
            <tr v-for="(dataItem , index) in fettchDataArray" :key="dataItem.id">                
              <td>{{ index + 1 }}</td>
              <td>{{ dataItem.product.name }}</td>
              <!-- <td v-if="userData?.type=='SUPERADMIN'">{{ dataItem.store?.name }}</td> -->
              <td>{{ dataItem.type }}</td>
              <td>
                <span v-if="dataItem.quantity">
                  <span v-if="dataItem.quantity >= 0" class="badge bg-success">{{ dataItem.quantity }} {{ dataItem.product.unit }}</span>
                  <span v-else class="badge bg-danger">{{ dataItem.quantity }} {{ dataItem.product.unit }}</span> 
                </span>
                <span v-else>00</span>
              </td>
              <td>{{ formatDateTime(dataItem.created_at) }}</td>
              <td>
                <!-- <button type="button" class="me-2 btn btn-sm btn-warning" @click="editItem(dataItem.id.toString())">
                  <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                </button> -->
                <!-- <button type="button" class="me-2 btn btn-sm btn-danger" data-bs-toggle="modal" data-bs-target="#staticBackdrop" @click="deleteclick(dataItem.id, dataItem.user_id)">
                  Delete
                </button> -->
                <!-- <button type="button" class="me-2 btn btn-sm btn-danger" 
                  data-bs-toggle="modal" data-bs-target="#modalCenter"
                  @click="fetchItem(dataItem)"
                >
                <font-awesome-icon :icon="['fas', 'trash-can']" />
                </button> -->
                <!-- <button type="button" class="me-2 btn btn-sm btn-primary btn-disable" @click="fetchItem(dataItem)" data-bs-toggle="modal" data-bs-target="#modalCenter"> + Add Stock </button> -->
              </td>
            </tr>
          </tbody>
        </table>
     </div>
   </div>
   <div class="mobile-view d-block d-md-none">
      <div class="card" v-if="loading">
        <div class="card-body">
          <div class="d-flex justify-content-center align-items-center" >
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-4" v-for="dataItem in fettchDataArray" :key="dataItem.id">
        <div class="card-body">
        <!-- <span>{{index+1}}</span><br> -->
        
        <strong> Name: {{ dataItem.product.name }}</strong><br/>
        <small>Type: {{ dataItem.type }} </small> <br/>
        <small>Quantity: 
          <span v-if="dataItem.quantity">
            <span v-if="dataItem.quantity >= 0" class="badge bg-success">{{ dataItem.quantity }} {{ dataItem.product.unit }}</span>
            <span v-else class="badge bg-danger">{{ dataItem.quantity }} {{ dataItem.product.unit }}</span> 
          </span>
          <span v-else>00</span>
        </small><br/>
        <small>Time:{{ formatDateTime(dataItem.created_at) }}</small>
          
        </div>
      </div>
    </div>

 </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import axios from '../../../../node_modules/axios/index';
import { useStore } from 'vuex'; // Import useStore from Vuex
import { DOAMIN_PATH } from "@/api";
interface mappingData {
  id: number,
  name: string,
  type: string,
  product_id: number,
  quantity: number,
  unit: string,
  user_id: number,
  store_id: number,
  created_at: string,
  product: {
      id: 10,
      name: string,
      unit: string,
      user_id: number,
      store_id: number,
  },
}
export default defineComponent({
name: "stock-summary", // Rename the component to a multi-word name
 data() {
   const store = useStore();
   const token = store.state.auth.token || localStorage.getItem('token');
   const userId = store.state.auth.userId || localStorage.getItem('userId');
   const storeId = store.state.auth.storeId || localStorage.getItem('storeId');
   let userData = store.state.auth.userData || localStorage.getItem('userData');
    userData = JSON.parse(userData);
   return {
       successReport: false,
       storeId,
       itemId: '',
       store,
       userData,
       token,
       userId, 
       fettchDataArray: ref([] as mappingData[]), // Explicitly define the type as Post[] // Explicitly define the type as Post[]
       loading: false,
       formData: {
         id: Number,
         type: '', 
         quantity:'',
         product_id: '',
         user_id:'',
         store_id:'',
         supplier_id:'',
       },
   }
 },

watch: {
 '$route.params.id': {
   immediate: true,
   handler(newVal) {
     if (newVal) {
       this.fetchData(newVal);
       this.itemId = newVal;
     }
   },
 },
},
methods: {
  formatDateTime(isoString: string | number | Date) {
      const date = new Date(isoString);

      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        // timeZoneName: 'short',
      };

      return date.toLocaleString(undefined, options);
    },

 async fetchData(product_id: number) {
   try {
    this.loading = true;
     if (!this.token) {
       throw new Error('Token not found');
     }     
     const response = await (axios as any).get(DOAMIN_PATH + `stock`, {
       headers: {
         Authorization: `Bearer ${this.token}`,
         ProductId: product_id,
         userId: this.userId,
       },
       params: {
        //  product_id: product_id,
        //  store_id: this.storeId,
        //  user_id: this.userId,
       }
     });
     if (response.status === 200) {
       this.fettchDataArray = response.data.data.data;
       this.loading = false;
      //  this.fettchDataArray.created_at = response.data.data.created_at;
          
      //     const date = new Date(this.fettchDataArray.created_at);
      //     const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      //   // Assign the formatted date to the data property
      //     this.fettchDataArray.created_at = formattedDate,
     }
   } catch (error) {
     console.error(error);
   }
 },
 async handleSubmit() {
   try {
     if (this.itemId) {
       this.formData.store_id = this.storeId;
       this.formData.user_id = this.userId;
       const response = await (axios as any).put(
         DOAMIN_PATH + 'stock/' + this.itemId,
         this.formData,
         {
           headers: {
             Authorization: `Bearer ${this.token}`,
             userId: this.userId,
             TypeId: this.userData.store[0].type_id,
           }
         }
       );
       if
       (response.status == 204) {
         console.log();
         this.successReport = true;
         setTimeout(() => { this.successReport = false; }, 3000);
       }
     } else {
       this.formData.store_id = this.storeId;
       this.formData.user_id = this.userId;
       const response = await (axios as any).post(DOAMIN_PATH + 'stock', 
        this.formData,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            }
          }
        );
        console.log('_category_respons_', response.data);
        if (response.status == 201) {
          this.formData = {
            id: Number,
            type: '', 
            quantity:'',
            product_id: '',
            supplier_id:'',
            user_id: this.userId,
            store_id: this.storeId,
          };
          this.successReport = true;
          setTimeout(() => { this.successReport = false; }, 3000);
        }
      
     }
   } catch (error) {
     console.error(error);
   }
 }
},
mounted() {
 // Set isLoading to true when the component is mounted
//  this.fetchProductFunc();
console.log('user_data', this.userData.store[0].type_id);
},
})
</script>