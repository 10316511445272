<template >
  <div class="layout-page">
    <TopBarView />
    <div class="content-wrapper">
      <!-- <Search /> -->
      <!-- Content -->
      <div>
        <div>
          <div class="flex-grow-1 container-p-y px-4">
            <div class="d-flex justify-content-between aling-items-center">
              <div>
                <h4 class="fw-bold py-3 mb-4">
                    <span class="text-muted fw-light">Type </span>Setting
                </h4>
              </div>
              <div>
                <button class="btn btn-primary mt-2" @click="toggleComponent" v-if="listShow"> + Add</button>
                <button class="btn btn-primary mt-2" @click="toggleComponent" v-if="formShow">Back to List</button>
              </div>
            </div>
            <!-- Basic Layout & Basic with Icons -->
            <div class="">
              <!-- Basic Layout -->
                <div class="">
                    <!-- table  -->
                  <div class="" v-if="listShow">
                    <div class="">
                      <div class="text-nowrap">
                        <List />
                      </div>
                    </div>
                  </div>
                  <!-- form..  -->
                  <div v-if="formShow">
                    <Form />
                  </div>                  
                </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <!-- / Footer -->

      <div class="content-backdrop fade"></div>
    </div>
    <!-- / Layout wrapper -->
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
// import ThemMenu from "../components/Navbar.vue";
// import Search from "../components/Search.vue";
import List from "./Components/List.vue";
import Form from "./Components/Form.vue";
import Footer from "../FooterView.vue";
import TopBarView from "../TopBarView.vue";
import router from "@/router";

export default defineComponent({
  // beforeEnter: (to: any, from: any, next: any) => {
  //     const userData = localStorage.getItem('userData') || '{}';
  //     const parsedUserData = JSON.parse(userData);
  //     console.log(parsedUserData.type);
      
  //     if (to.matched.some((record: { meta: { requiresSuperAdmin: any; }; }) => record.meta.requiresSuperAdmin) && parsedUserData.type !== 'SUPERADMIN') {
  //       console.log('Access denied for non-SuperAdmin');
  //       next('/');
  //     } else {
  //       console.log('Access granted');
  //       next();
  //     }
  //   },
  // beforeRouteEnter(to, from, next) {
  //   // Check if :id parameter is present
  //   if (!to.params.id) {
  //     next({ name: 'not-found' }); // Redirect to the 404 page
  //   } else {
  //     next();
  //   }
  // },
  // beforeRouteEnter(to, from, next) {
  //   // Check if :id parameter is present
  //     const userData = localStorage.getItem('userData') || '{}';
  //     const parsedUserData = JSON.parse(userData);
  //     // console.log(parsedUserData.type);
  //   if (parsedUserData.type === 'SUPERADMIN') {
  //     next(); // Redirect to the 404 page
  //   } else {
  //     next('/');
  //   }
  // },

  components: { List, Form, Footer, TopBarView},
  name: "type-view",
  data() {
    return {
      listShow: true,
      formShow: false,
    };
  },
  watch: {
    '$route.params.id': {
      // immediate: true,
      handler(newVal) {
        this.listShow = !this.listShow;
        this.formShow = !this.formShow;
      },
    },
  },
  methods: {
    toggleComponent() {
      this.listShow = !this.listShow; // Toggles the visibility
      this.formShow = !this.formShow; // Toggles the visibility
      if(this.$route.params.id){
        this.listShow = !this.listShow;
        this.formShow = !this.formShow;
        router.push({ name: "type-view" });
      }
    },
  },

  mounted() {
    // Set isLoading to true when the component is mounted
    //this.showLoadingOverlay();
  },

});
</script>