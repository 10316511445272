<template>
  <!-- search form start  -->
  <div class="card mb-4 d-none">
    <div class="card-body">    
      <div class="row">
        <div class="input-group">
            <input type="text" class="form-control w-25" placeholder="Enter Search Key !">
            <div class="input-group">
                <span class="input-group-text" id="basic-addon1">From</span>
                <input type="date" class="form-control" placeholder="Enter Search Key !">
                <span class="input-group-text" id="basic-addon1">To</span>
                <input type="date" class="form-control" placeholder="Enter Search Key !">
            </div>
            <button type="button" class="btn btn-primary me-1 ms-1"><font-awesome-icon :icon="['fas', 'magnifying-glass']" /></button>
            <button type="button" class="btn btn-default border"><font-awesome-icon :icon="['fas', 'circle-xmark']" /></button>            
          </div>
      </div>
    </div>
  </div>
  <!-- search form end  -->
  <div>
    <!-- <div class="alert alert-success" v-if="successStatus">Company Switch Successfully !</div> -->
     <!-- table start  -->
    <div class="card mb-1 d-none d-md-block">
      <div class="card-body table-responsive">
        <div class="d-flex justify-content-center align-items-center" v-if="loading">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      <table class="table card-table" v-else>
        <thead>
          <tr>
            <th>SL</th>
            <!-- <th>Email</th> -->
            <th>Name</th>
            <!-- <th>Phone</th> -->
            <th v-if="userData.type == 'USER'">Phone</th>
            <th v-if="userData.type == 'USER'">Email</th>
            <th v-if="userData.type == 'USER'">Address</th>
            <th v-if="userData.type == 'USER'">Is Default</th>
            <th v-if="userData.type == 'SUPERADMIN'">Phone</th>
            <th v-if="userData.type == 'SUPERADMIN'">Email</th>
            <th v-if="userData.type == 'SUPERADMIN'">Address</th>
            <th v-if="userData.type == 'USER'">Action</th> 
          </tr>
        </thead>
        <tbody class="table-border-bottom-0">
          <tr v-for="(store, index)  in dataArray" :key="store.id">
            <td>{{ index + 1 }}</td>
            <!-- <td>{{ store.email }}</td> -->
            <td>{{ store.name }}</td>
            <!-- <td>{{ store.phone }}</td> -->
            <!-- <td>{{ store.is_default }}</td> -->
            <td v-if="userData.type == 'USER'">
              {{ store.phone }}
            </td>
            <td v-if="userData.type == 'USER'">
              {{ store.email }}
            </td>
            <td v-if="userData.type == 'USER'">
              {{ store.address }}
            </td>
            <td v-if="userData.type == 'USER'">
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" :id="'flexSwitchCheckChecked' + store.id" :checked="store.is_default == 1 ? true : false" @click="setAsDefault(store.id)">
              </div>
            </td>
            <td v-if="userData.type == 'SUPERADMIN'">
              {{ store.user.phone }}
            </td>
            <td v-if="userData.type == 'SUPERADMIN'">
              {{ store.user.email }}
            </td>
            <td v-if="userData.type == 'SUPERADMIN'">
              {{ store.user.address }}
            </td>
            <td v-if="userData.type == 'USER'">
              <!-- <button type="button" class="me-2 btn btn-warning" @click="sendData(post.id.toString())">
                Edit
              </button> -->
              <button type="button" class="me-2 btn btn-sm btn-warning" @click="editItem(store.id.toString())">
                <font-awesome-icon :icon="['fas', 'pen-to-square']" />
              </button>
              <!-- <button @click="editItem(post)">Edit</button> -->
              <button type="button" class="me-2 btn btn-sm btn-danger" v-if="store.is_default == 0" data-bs-toggle="modal" data-bs-target="#staticBackdrop" @click="deleteclick(store.id, store.name)">
                <font-awesome-icon :icon="['fas', 'trash-can']" />
              </button>
            </td>            
          </tr>
        </tbody>
      </table>
      </div> 
    </div>
    <div class="mobile-view d-block d-md-none">
      <div class="card mb-4" v-for="store in dataArray" :key="store.id">
        <div class="card-body">
        <!-- <span>{{index+1}}</span><br> -->
        <strong> Name: {{ store.name }}</strong><br/>
        <small v-if="userData.type == 'USER'">Phone: {{ store.phone }}</small> <br/>
        <small v-if="userData.type == 'USER'">Email: {{ store.email }}</small>
        <p v-if="userData.type == 'USER'" class="text-wrap">Address: {{ store.address }}</p>
        <small v-if="userData.type == 'SUPERADMIN'">Phone: {{ store.user.phone }}</small> <br v-if="userData.type == 'SUPERADMIN'"/>
        <small v-if="userData.type == 'SUPERADMIN'">Email: {{ store.user.email }}</small>
        <p v-if="userData.type == 'SUPERADMIN'"  class="text-wrap">Address: {{ store.user.address }}</p>
        <div v-if="userData.type == 'USER'" class="form-check form-switch mb-3" >
          <input class="form-check-input" type="checkbox" role="switch" :id="'flexSwitchCheckChecked' + store.id" :checked="store.is_default == 1 ? true : false" @click="setAsDefault(store.id)">
        </div>
        <div  v-if="userData.type == 'USER'">
          <button type="button" class="me-2 btn btn-sm btn-warning" @click="editItem(store.id.toString())">
            <font-awesome-icon :icon="['fas', 'pen-to-square']" /> Edit
          </button>
          <button type="button" class="me-2 btn btn-sm btn-danger" v-if="store.is_default == 0" data-bs-toggle="modal" data-bs-target="#staticBackdrop" @click="deleteclick(store.id, store.name)">
                  <font-awesome-icon :icon="['fas', 'trash-can']" /> Delete
          </button>
        </div>
        </div>
      </div>
    </div>
    <!-- // table end -->
    <!-- pagination start  -->
    <div class="card">
      <div class="card-body d-flex justify-content-around flex-wrap">
        <nav aria-label="Page navigation">
          <ul class="pagination flex-wrap">
            <!-- Page buttons -->
              <li v-for="(page, index) in paginationData.links" :key="index" class="page-item myPagination m-1" :class="{ active: page.active }">
                <button class="page-link" @click="goToPage(Number(page.label))" v-html="page.label"></button>
              </li>
          </ul>
        </nav>
        <div class="row">
          <div class="col-12">
            <div class="input-group ">
              <label class="input-group-text" for="inputGroupSelect01">Size</label>
              <select class="form-select" id="inputGroupSelect01" v-model="pageSize" @change="fetchData">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
        </div>
      </div> 
    </div>
    <!-- // pagination end -->
    <!-- Modal delete modal -->
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Confirm to Delete</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>Are You Sure want to delete this <strong>{{ deletevalue.title }}</strong> ?</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="myCloseButton">Close</button>
            <button type="button" class="btn btn-danger" @click="deleteItem(deletevalue.id)">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import axios from '../../../../node_modules/axios/index';
import { useStore } from 'vuex'; // Import useStore from Vuex
import { DOAMIN_PATH } from "@/api";
import router from '@/router';
interface DataArray {
  id: number;
  store_id: '',
  email: '',
  name: '',
  phone: '',
  address: '',
  user: {
    id: number;
    name: '',
    phone: '',
    address: '',
    email: '',
    email_verified_at: '',
    created_at: '',
    updated_at: '',
  },
  is_default: number;
  user_id: '', // Assuming this property exists
}
interface CustomHeaders {
  Authorization: string;
  UserId?: any;
  StoreId?: string; // Make StoreId optional as it's conditionally added
}
export default defineComponent({
  name: "CategoryList", // Rename the component to a multi-word name
  data() {
    const store = useStore();
    const token = store.state.auth.token || localStorage.getItem('token');
    const userId =  store.state.auth.userId || localStorage.getItem('userId');
    let userData = store.state.auth.userData || localStorage.getItem('userData');
    userData = JSON.parse(userData);
    return {
      dataArray: ref([] as DataArray[]), // Explicitly define the type as Post[] // Explicitly define the type as Post[]
      userData: userData,
      iscomponent: true,
      isupdate: true,
      isLoading: false,
      fullPage: false,
      isdata: true,
      // successStatus: false,
      userId,
      deletevalue: {
        id: 0,
        title: "",
      },
      isDataTrueList: true,
      id: '', // Replace with your actual ID value
      store,
      token,
      loading: true,
      pageSize: 10, // set a default value
      currentPage: 1, // set a default value
      paginationData: {
        links: [
          {
            label: '',
            url: '',
            active: '',
          }
        ],
        prev_page: 0,
        next_page: 0,
        total: 0,
        per_page: 0,
        current_page: 0,
        last_page: 0,

      }, // Object to store pagination data
    };
  },
  methods: {
    async getAllData() {
      try {
        this.loading = true;
        if (!this.token) {
            throw new Error('Token not found');
          }
          const headers: CustomHeaders = {
            Authorization: `Bearer ${this.token}`,
            // UserId: this.userId,
        };
        if (this.userData.type === "USER") {
            headers.UserId = this.userId;
        }
          const response = await axios.get(DOAMIN_PATH + 'store',
          {
            params: {
              page: this.currentPage,
              size: this.pageSize,
            },
            headers: {
              // Authorization: `Bearer ${this.token}`,
              // UserId: this.userId,
              ...headers,
            }
          }
          );
          
        if (response.status === 200) {
          this.dataArray = response.data.data.data;
          this.paginationData = response.data.data;
          this.loading = false;
        }
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    async deleteItem(id: number) {
        try {
          if (!this.token) {
            throw new Error('Token not found');
          }
          const response = await axios.delete(DOAMIN_PATH + `store/${id}`, {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          });

          if (response.status === 200) {
            const closeBtn = document.getElementById('myCloseButton');
            if (closeBtn) {
              closeBtn.click();
            }
            this.dataArray = this.dataArray.filter((item: any) => item.id !== id);
          }
        } catch (error) {
          alert('Error occurred while deleting item');
          console.error(error);
        }
    },
    deleteclick(id: number, title: string) {
      //this.editvalue = `${title} (${icon}): ${link}`;
      this.deletevalue.id = id;
      this.deletevalue.title = title;
    },
    sendData(id: string) {
      this.$emit('data-emitted', {
        isDataTrueList: this.isDataTrueList,
        id,
      });
    },
    // editItem(post: { id: number; store_id: string; email: string; name: string; phone: string; user: object; user_id: string; }) {
    //   this.$emit('edit-item', post);
    // },
    editItem(id: string) {
      // Navigate to the edit route with the ID parameter
      this.$router.push({ params: { id } });
    },
     // changeStatus function 
     async setAsDefault(id: number) {
      try {
        const response = await (axios as any).get(DOAMIN_PATH + 'store/' + id + '/set_default', {
          headers: {
            Authorization: `Bearer ${this.token}`,
            UserId: this.userId,
          },
        });
        if (response.status == 200) {
          // console.log('_update_success', response.data.data);
          const storeData = response.data.data;
          const storeId = storeData.id;
          const typeId = storeData.type_id;
          this.store.commit('auth/SET_DEFAULT_STORE_DATA', storeData);
          localStorage.setItem('defaultStoreData', JSON.stringify(storeData));
          this.store.commit('auth/SET_STORE_ID', storeId);
          this.store.commit('auth/SET_TYPE_ID', typeId);
          localStorage.setItem('storeId', storeId);
          localStorage.setItem('typeId', typeId);
          window.location.reload()   
          // this.getAllData();   
          // this.successStatus = true;
          // setTimeout(() => {
          //   this.successStatus = false
          // }, 2000);
        }
      } catch (error) {
        console.error(error);
      }
    },

    //pagination function
    async fetchData() {
    // Optionally, you can reset the current page to 1 when the page size changes
    // this.currentPage = 1;
      await this.getAllData();
    },
    goToPage(pageOrLabel: string | number | null) {
      
    if (pageOrLabel === "Next »" || pageOrLabel === "&laquo; Previous") {
      console.log('Skipping Next/Previous');
      return;
    }
    if (typeof pageOrLabel === 'number') {
      console.log('Setting currentPage  :', pageOrLabel);
      this.currentPage = pageOrLabel;
      console.log('curentPage:', this.currentPage);
      this.getAllData();
    } else if (pageOrLabel) {
      const match = pageOrLabel.match(/page=(\d+)/);
      if (match) {
        console.log('else id match:', parseInt(match[1]));
        this.currentPage = parseInt(match[1]);
      }
    }

    this.getAllData();
  },
  },
  mounted() {
    this.getAllData();
  },
});
</script>