<template>
     <div class="">
      <!-- Basic Layout & Basic with Icons -->
      <div v-if="successReport" class="alert alert-success alert-dismissible" role="alert">
        Successfully
        <button type="button" class="btn-close"  aria-label="Close"></button>
      </div>
    </div>
    <div class="row">
    <div class="card-body ">
      <div class="card py-3">
        <div class="d-flex justify-content-center align-items-center" v-if="loading">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="row invoice-preview" v-else>
          <!-- Invoice -->
          <div class="col-xl-9 col-md-8 col-12 mb-md-0 mb-4">
            <div class="card invoice-preview-card">
              <div class="card-body">
                <div class="d-flex justify-content-between flex-xl-row flex-md-column flex-sm-row flex-column p-sm-3 p-0">
                  <div class="mb-xl-0 mb-4">
                    <div class="d-flex svg-illustration mb-4 gap-2">
                      <!-- <span class="app-brand-logo demo">
                        <img src="https://phicsart.com/uploads/images/top-page/menu/logo.svg" alt="" class="img-fluid">
                      </span>
                      <span class="app-brand-text demo menu-text fw-bolder ms-2 text-uppercase">POS</span> -->
                      <span class="app-brand-text demo menu-text fw-bolder ms-2 text-uppercase">Company</span>
                    </div>
                    <p class="mb-1">House: E/15, Block: E, Zakir Hossain Road</p>
                    <p class="mb-1">Lalmatia, Dhaka - 1207</p>
                    <p class="mb-0">+880 1881 242071, +880 1710 001337</p>
                  </div>
                  <div>
                    <h4>Invoice No:  {{ orderData.invoice_no }}</h4>
                    <div class="mb-2">
                      <span class="me-1">Date Issues:</span>
                      <span class="fw-medium">{{ orderData.created_at }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-0">
              <div class="card-body">
                <div class="row p-sm-3 p-0">
                  <div class="col-xl-12 col-md-12 col-sm-7 col-12">
                    <h6 class="pb-2">Bill To:</h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pe-3">Cutomer Name</td>
                          <td>{{orderData?.customer_name}}</td>
                        </tr>
                        <tr>
                          <td class="pe-3">Customer Type</td>
                          <td>{{ orderData?.customer_type }}</td>
                        </tr>
                        <tr>
                          <td class="pe-3">Customer Phone</td>
                          <td>{{ orderData?.customer_phone }}</td>
                        </tr>
                        <tr>
                          <td class="pe-3">Address:</td>
                          <td>{{ orderData?.customer_address}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="table-responsive mx-4">
                <table class="table table-bordered m-0 text-center">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Name</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="dataItem in orderData.order_items" :key="dataItem.id">
                      <td>{{ dataItem.id }}</td>
                      <td>{{ dataItem.product_id }}</td>                
                      <td>{{ dataItem.sell_price }}</td>
                      <td>{{ dataItem.quantity }}</td>
                      <td>{{ dataItem.total }}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th colspan="4" class="text-end">Sub Total =</th>
                      <th>{{ orderData.sub_total }}</th>
                    </tr>
                    <tr>
                      <th colspan="4" class="text-end">Discount = </th>
                      <th>{{ orderData.discount }}</th>
                    </tr>
                    <tr>
                      <th colspan="4" class="text-end">Total =</th>
                      <th>{{ orderData.total }}</th>
                    </tr>
                  </tfoot>
                    <!-- <tr>
                      <td colspan="3" class="align-top px-4 py-5">
                        <p class="mb-2">
                          <span class="me-1 fw-medium">Sale By:</span>
                          <span>Zahid Hassan</span>
                        </p>
                        <span>Thanks from <strong>Business Name</strong></span>
                      </td>
                      <td class="text-end px-4 py-5">
                        <p class="mb-2">Subtotal:</p>
                        <p class="mb-2">Discount:</p>
                        <p class="mb-0"><strong> Total:</strong></p>
                      </td>
                      <td class="px-4 py-5">
                        <p class="fw-medium mb-2">{{ orderData.total }}</p>
                        <p class="fw-medium mb-2">{{ orderData.discount }} %</p>
                        <p class="fw-medium mb-2"><strong> {{ orderData.sub_total }}</strong></p>
                      </td> -->
                    <!-- </tr> -->
                  <!-- </tbody> -->
                </table>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <!-- <span class="fw-medium">Note:</span> -->
                    <span>Software Design & Development by ❤️ <strong>PhicsArt</strong>!</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Invoice -->
          <!-- Invoice Actions -->
          <div class="col-xl-3 col-md-4 col-12 invoice-actions">
            <div class="card">
              <div class="card-body">
                <button class="btn btn-primary d-grid w-100 mb-3" data-bs-toggle="offcanvas" data-bs-target="#sendInvoiceOffcanvas">
                  <span class="d-flex align-items-center justify-content-center text-nowrap"><i class="bx bx-paper-plane bx-xs me-1"></i>Send Invoice To Cutomer</span>
                </button>
                <button class="btn btn-label-secondary btn-default border d-grid w-100 mb-3">
                  Download
                </button>
                <button class="btn btn-label-secondary border d-grid w-100 mb-3" @click="printInvoice">
                  Print
                </button>
              </div>
            </div>
          </div>
          <!-- /Invoice Actions -->
        </div>
        <!-- <div class="invoice-print p-5">
          <div class="d-flex justify-content-between flex-row">
            <div class="mb-4">
              <div class="d-flex svg-illustration mb-4 gap-2">
                <span class="app-brand-text demo menu-text fw-bolder ms-2 text-uppercase">Company</span>
              </div>
              <p class="mb-1">House: E/15, Block: E, Zakir Hossain Road</p>
              <p class="mb-1">Lalmatia, Dhaka - 1207</p>
              <p class="mb-0">+880 1881 242071, +880 1710 001337</p>
            </div>
            <div>
              <h4>Invoice No:  {{ orderData.invoice_no }}</h4>
              <div class="mb-2">
                <span class="me-1">Date Issues:</span>
                <span class="fw-medium">{{ orderData.created_at }}</span>
              </div>
            </div>
          </div>
          <hr>
          <div class="row d-flex justify-content-between mb-4">
            <div class="col-sm-6 w-50">
              <h6 class="pb-2">Bill To:</h6>
              <table>
                <tbody>
                  <tr>
                    <td class="pe-3">Cutomer Name</td>
                    <td>{{orderData?.customer_name}}</td>
                  </tr>
                  <tr>
                    <td class="pe-3">Customer Type</td>
                    <td>{{ orderData?.customer_type }}</td>
                  </tr>
                  <tr>
                    <td class="pe-3">Customer Phone</td>
                    <td>{{ orderData?.customer_phone }}</td>
                  </tr>
                  <tr>
                    <td class="pe-3">Address:</td>
                    <td>{{ orderData?.customer_address}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table border-top m-0">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="dataItem in orderData.order_items" :key="dataItem.id">
                  <td>{{ dataItem.id }}</td>
                  <td>{{ dataItem.product_id }}</td>                
                  <td><strong>{{ dataItem.sell_price }}</strong></td>
                  <td>{{ dataItem.quantity }}</td>
                  <td><strong> {{ dataItem.total }}</strong></td>
                </tr>
                <tr>
                  <td colspan="3" class="align-top px-4 py-5">
                    <p class="mb-2">
                      <span class="me-1 fw-medium">Sale By:</span>
                      <span>Zahid Hassan</span>
                    </p>
                    <span>Thanks from <strong>Business Name</strong></span>
                  </td>
                  <td class="text-end px-4 py-5">
                    <p class="mb-2">Subtotal:</p>
                    <p class="mb-2">Discount:</p>
                    <p class="mb-0"><strong> Total:</strong></p>
                  </td>
                  <td class="px-4 py-5">
                    <p class="fw-medium mb-2">{{ orderData.total }}</p>
                    <p class="fw-medium mb-2">{{ orderData.discount }} %</p>
                    <p class="fw-medium mb-2"><strong> {{ orderData.sub_total }}</strong></p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-12">
              <span>Software Design & Development by ❤️ <strong>PhicsArt</strong>!</span>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
// import CkEditor from "../../../components/Ckeditor.vue";
import axios from '../../../../node_modules/axios/index';
import { useStore } from 'vuex'; // Import useStore from Vuex
import { DOAMIN_PATH } from "@/api";
export default defineComponent({
  name: "order-form", // Rename the component to a multi-word name
    data() {
      const store = useStore();
      const token = store.state.auth.token || localStorage.getItem('token');
      const userId = store.state.auth.userId || localStorage.getItem('userId');
      const companyId = store.state.auth.companyId || localStorage.getItem('companyId');
      return {
          successReport: false,
          companyId,
          itemId: '',
          store,
          token,
          userId, 
          orderData: {
            id: 0,
            customer_id: '',
            user_id: '',
            company_id: '',
            invoice_no: "",
            customer_type: "",
            total: "",
            discount: "",
            sub_total: "",
            payment: "",
            created_at: "",
            customer_phone: "",
            customer_name: "",
            customer_address: "",
            order_items: [
              {
                id: 0,
                buy_price: "",
                sell_price: "",
                quantity: "",
                discount: "",
                total: "",
                order_id: "",
                product_id: "",
                customer_id: "",
                user_id: "",
                company_id: "",
              }
          ]
          },
          loading: false,
      }
    },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.fetchData(newVal);
          this.itemId = newVal;
        }
      },
    },
  },

  methods: {
    async fetchData(id: number) {
      try {
        this.loading = true;
        if (!this.token) {
          throw new Error('Token not found');
        }
        const response = await (axios as any).get(DOAMIN_PATH + `order/${id}`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });
        if (response.status === 200) {
          this.orderData = response.data.data;
          console.log('_page_data_by_ID_',this.orderData);
          const date = new Date(this.orderData.created_at);
          const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
        // Assign the formatted date to the data property
          this.orderData.created_at = formattedDate
          this.loading = false;
        }
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    async handleSubmit() {
      try {
        if (this.itemId) {
          const response = await (axios as any).put(
            DOAMIN_PATH + 'order/' + this.itemId,
            this.orderData,
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
              }
            }
          );
          if
          (response.status == 204) {
            console.log();
            this.successReport = true; 
            setTimeout(() => { this.successReport = false; }, 3000);
          }
        } else {
          this.orderData.company_id = this.companyId;
          this.orderData.user_id = this.userId;

          const response = await (axios as any).post(DOAMIN_PATH + 'order', 
          this.orderData,
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
              }
            }
          );
          if (response.status == 201) {
            this.orderData = {
              id: 0,
              customer_id: '',
              invoice_no: "",
              customer_type: "",
              total: "",
              discount: "",
              sub_total: "",
              payment: "",
              user_id: this.userId,
              company_id: this.companyId,
              created_at: "",
              customer_phone: "",
              customer_name: "",
              customer_address: "",
              order_items: [
                  {
                    id: 0,
                    buy_price: "",
                    sell_price: "",
                    quantity: "",
                    discount: "",
                    total: "",
                    order_id: "",
                    product_id: "",
                    customer_id: "",
                    user_id: "",
                    company_id: "",
                  }
              ]
            };
            this.successReport = true;
            setTimeout(() => { this.successReport = false; }, 3000);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    printInvoice() {
    // Open a new window for printing
    const printWindow = window.open("", "_blank");

    // Check if the print window is available
    if (printWindow) {
      // Create the content you want to print (e.g., your invoice HTML)
      const invoiceContent = this.generateInvoiceContent();

      // Write the content to the print window
      printWindow.document.write(invoiceContent);

      // Trigger the print function in the print window
      printWindow.document.addEventListener("DOMContentLoaded", () => {
        printWindow.print();
        printWindow.onafterprint = () => {
          // Close the print window after printing
          printWindow.close();
        };
      });
    } else {
      // Handle if the print window is not available
      alert("Error: Unable to open the print preview.");
    }
  },

  generateInvoiceContent() {
    // Replace this with the logic to generate your invoice content (HTML)
    // For example, you can use this.orderData to get the invoice data
    // and create an HTML structure for the invoice.
    const invoiceHtml = "<html><head></head><body><h1>Invoice Content</h1><table><thead> <tr> <th>Id</th> <th>Name</th> <th>Price</th> <th>Quantity</th> <th>Total</th> </tr> </thead> <tbody> <tr> <td>1</td> <td>Product 1</td> <td>$10.00</td> <td>1</td> <td>$10.00</td> </tr> <tr> <td>2</td> <td>Product 2</td> <td>$20.00</td> <td>2</td> <td>$40.00</td> </tr> </tbody></table></body></html>";

    return invoiceHtml;
  },
  },
mounted() {
    // Set isLoading to true when the component is mounted
  },
})
</script>